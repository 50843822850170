exports.components = {
  "component---src-components-visiting-room-visiting-room-profile-js": () => import("./../../../src/components/VisitingRoom/VisitingRoomProfile.js" /* webpackChunkName: "component---src-components-visiting-room-visiting-room-profile-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-visiting-room-js": () => import("./../../../src/pages/visiting-room.js" /* webpackChunkName: "component---src-pages-visiting-room-js" */)
}

